import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import * as config from "@/utils/config";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refProductsTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "product_code", label: "Mã thuốc", sortable: false },
    {
      key: "name",
      label: "Tên thuốc",
      sortable: false,
      thStyle: { width: "12%" },
    },
    { key: "active_ingredient", label: "Hoạt chất", sortable: false },
    { key: "quality", label: "Hàm lượng", sortable: false },
    { key: "concentration", label: "Nồng độ", sortable: false },
    { key: "unit_id", label: "Đơn vị", sortable: false },
    {
      key: "lot_number",
      label: "Số lô",
      sortable: false,
    },
    { key: "expired_date", label: "Hạn dùng", sortable: false },
    {
      key: "registration_number",
      label: "Số đăng ký",
      sortable: false,
    },
    { key: "price_in", label: "Giá mua", sortable: false },
    { key: "price_out", label: "Giá bán", sortable: false },
    // { key: "action", label: "", sortable: false },

    // { key: "in_date", label: "Ngày nhập", sortable: false },
  ];
  const perPage = ref(15);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 15, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const activeFilter = ref(null);
  const productBrands = ref([]);
  const productCategories = ref([]);
  const productList = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refProductsTable.value
      ? refProductsTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const pharma_id = computed(() => Vue.prototype.$pharmacyInfo.id);

  const refetchData = () => {
    if (!refProductsTable.value) return;
    refProductsTable.value.refresh();
  };
  const fetchBrands = (ctx, callback) => {
    store.dispatch("phamarProductInventory/fetchBrands").then((res) => {
      productBrands.value = res.data.data.map((x) => ({
        label: x.Name,
        value: x.ID,
      }));
    });
  };
  const fetchCategories = (ctx, callback) => {
    store.dispatch("phamarProductInventory/fetchCategories").then((res) => {
      productCategories.value = res.data.data.map((x) => ({
        label: x.Name,
        value: x.ID,
      }));
    });
  };
  fetchBrands();
  fetchCategories();
  const fetchPharmaProducts = (ctx, callback) => {
    const params = {
      keyword: searchQuery.value,
      page_num: currentPage.value,
      page_size: perPage.value,
      pharma_id: pharma_id.value,
      sort_by: "id",
      order: "desc",
    };

    store
      .dispatch("phamarProduct/getPharmaProducts", params)
      .then((response) => {
        const products = response.data.data;
        totalProducts.value = response.data?.page?.total;
        productList.value = products;
        callback(products);
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tags list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const timeout = ref(null);

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
      pharma_id,
    ],
    () => {
      showTable.value = true;
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 1000);
    }
  );
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchPharmaProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductsTable,
    refetchData,
    productBrands,
    productCategories,
    productList,
    pharma_id,
    // Extra
  };
}
